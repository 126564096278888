import React, { Component } from "react";
import NavBar from "./Navbar";
import Contacts from "./sections/Contacts";
import News from "./sections/News";
import Pages from "./sections/Pages";
import Requests from "./sections/Requests";
import TimeSchedule from "./sections/timeSchedule/TimeSchedule";

const showNews = true;

class Content extends Component {
  render() {
    return (
      <div className="w-100">
        <div id="home" />
        <NavBar />
        {showNews && <News />}
        <TimeSchedule />
        <Requests disabled={showNews} />
        <Contacts />
        <Pages />
      </div>
    );
  }
}

export default Content;
