import React, { Component } from "react";
import { ExclamationDiamond } from "react-bootstrap-icons";
import Section from "../../../components/Section";

class News extends Component {
  render() {
    return (
      <Section
        sectionTitle="Attenzione"
        sectionId="news"
        sectionBackgroundClass="section-background-1"
        noMinHeight="true"
      >
        <p className="center">
          <span className="text-danger display-4">
            <ExclamationDiamond /> <span>Attenzione!</span>
          </span>
        </p>
        <p className="center text-danger display-6">
          <span>
            Sarò assente fino al 17 settembre.
            <br />
            Per richiesta di impegnative, rinnovo ricette per medicinali,
            informazioni e prenotazione di appuntamenti chiamare in segreteria
            al numero 0461766025.
            <br />
            In caso di necessità, sono sostituita dalla Dott.ssa Debortoli nei
            suoi{" "}
            <a
              target="_blank"
              href="https://servizi.apss.tn.it/ricmedico/medico.php?codMedicoMg=15295"
              rel="noreferrer"
            >
              orari e sede
            </a>
            &nbsp; di ambulatorio
            <br /> (per urgenze 3775953375).
            <br />
            Le{" "}
            <b>
              <u>email</u>
            </b>{" "}
            o le{" "}
            <b>
              <u>richieste tramite sito</u>
            </b>{" "}
            pervenute nel periodo in cui sono assente{" "}
            <b>
              <u>non</u>
            </b>{" "}
            saranno prese in carico.
          </span>
        </p>
      </Section>
    );
  }
}

export default News;
