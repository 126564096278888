import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Section from "../../../components/Section";

class Requests extends Component {
  state = {
    validated: false,
  };

  handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.setState({ ...this.state, validated: true });
  }

  render() {
    const text_privacy = (
      <div>
        Ho letto e accetto l'
        <a href="/policy" target="_blank">
          informativa sulla privacy
        </a>
      </div>
    );

    return (
      <Section
        sectionTitle="Richieste"
        sectionId="requests"
        sectionBackgroundClass="section-background-2"
      >
        <Form
          noValidate
          validated={this.state.validated}
          action={"/request"}
          method="POST"
          onSubmit={this.handleSubmit.bind(this)}
          onClick={() => {
            if (this.props.disabled) {
              window.location.href = "#news";
            }
          }} // to scroll the page back to the news section if disabled
        >
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formRequestName" sm={4}>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="input"
                  name="name"
                  placeholder="Es: Mario"
                  required
                  disabled={this.props.disabled}
                />
                <Form.Control.Feedback type="invalid">
                  Per favore inserire un nome per continuare
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="formRequestFamilyName"
                sm={4}
              >
                <Form.Label>Cognome</Form.Label>
                <Form.Control
                  type="input"
                  name="family"
                  placeholder="Es: Rossi"
                  required
                  disabled={this.props.disabled}
                />
                <Form.Control.Feedback type="invalid">
                  Per favore inserire un cognome per continuare
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formRequestDate" sm={4}>
                <Form.Label>Data di nascita</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  placeholder="mm/gg/aa"
                  required
                  disabled={this.props.disabled}
                />
                <Form.Control.Feedback type="invalid">
                  Per favore inserire la data di nascita
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formRequestType" sm={4}>
                <Form.Label>Tipo di richiesta</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  placeholder="Selezionare il tipo di richiesta"
                  required
                  disabled={this.props.disabled}
                >
                  <option key="blankChoice" hidden value />
                  <option value="Rinnovo ricetta farmaci">
                    Rinnovo ricetta farmaci (assunti in modo continuativo)
                  </option>
                  <option value="Presidi sanitari">
                    Presidi sanitari (Es: lancette pungidito, pannoloni,
                    cateteri)
                  </option>
                  <option value="Esami del sangue o visite richieste da Specialista">
                    Esami del sangue o visite richieste da Specialista
                  </option>
                  <option value="Esami di controllo per la terapia in corso">
                    Esami di controllo per la terapia in corso (Es: terapia
                    anticoagulante)
                  </option>
                  <option value="Altro">Altro</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Per favore inserire la tipologia della richiesta
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formRequestContent">
                <Form.Label>Descrizione richiesta</Form.Label>
                <Form.Control
                  as="textarea"
                  name="request"
                  rows={4}
                  placeholder="Es: nome farmaco e dosaggio, tipo di visita"
                  required
                  disabled={this.props.disabled}
                />
                <Form.Control.Feedback type="invalid">
                  É necessario specificare la causa della richiesta
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formRequestEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Es: lamiaemail@email.com"
                  required
                  disabled={this.props.disabled}
                />
                <Form.Control.Feedback type="invalid">
                  Un indirizzo email è richiesto per inviare la conferma di
                  ricezione
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formRequestPhone">
                <Form.Label>Telefono</Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  placeholder="Es: 321456789"
                  required
                  disabled={this.props.disabled}
                />
                <Form.Control.Feedback type="invalid">
                  Un numero di telefono è richiesto nel caso fosse necessario un
                  contatto diretto
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formRequestPrivacy">
                <Form.Check
                  required
                  label={text_privacy}
                  feedback="É necessario accettare la visione dell'informativa per poter continuare"
                  feedbacktype="invalid"
                  disabled={this.props.disabled}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                type="submit"
                className="w-100 mb-5"
                disabled={this.props.disabled}
              >
                Conferma ed invia
              </Button>
            </Col>
          </Row>
        </Form>
      </Section>
    );
  }
}

export default Requests;
